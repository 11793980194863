.App, #root {
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.App {
  position: relative;
}

.logo_holder {
  /* min-height: 60vh;
  max-height: 70vh;
  max-width: 90vw; */
  height: 500px;
  width: 500px;
  border-radius: 300px;
  overflow: visible;
  
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
justify-content: center;
align-items: center;

transition: .5s all;


}
.l_circle {

  
  max-height: 70vh;
  max-width: 90vw;
  width: 350px;
  height: 350px;

  border-radius: 100vw;
  border: 50px solid blue;
  position: absolute;

}
.blend {
  mix-blend-mode: color-dodge;
  
}


.l_line {
  height: 500px;
  width: 50px;
  background-color: blue;
  position: absolute;
  

}
.l_center {
  animation: logo-flc-2 infinite 5s ease-in-out;
}
.l_left {
  transform: translateX(-110px);
  animation: logo-flc-1 infinite 5s ease-in-out;
}
.l_right {
  transform: translateX(110px);
  animation: logo-flc-3 infinite 5s ease-in-out;
}
.l_butt {
    padding: 8px;
    padding-left: 48px;
    padding-right: 48px;
    border-radius: 4px;
    opacity: 0;
    border: 1px solid black;
    cursor: pointer;
    color: black;
    position: absolute;
    transition: .3s all;
    font-family: monospace;
    font-weight: bold;
}

.logo_holder:hover > .l_butt {
  opacity: 1;
  border: 1px solid white;
    
    color: white;
}

.logo_holder:hover > .pcircle {
  opacity: 1;
}
.logo_holder:hover > .pcircle-word {
  opacity: 0;
}
.logo_holder:hover > .l_butt:hover {
  background-color: rgba(255,255,255,.1);
}

.App-bg {
  width: calc(100% + 0px);
  height: calc(100% + 0px);
  
  object-fit: fill;
  position: absolute;
  left: 0;
  transition: 1s all;
  filter: contrast(.9) blur(10px)  brightness(1.33)  hue-rotate(-10deg);
  
}

.App-logo {
  width: 100%;
  
  height: 100%;
  width: 510px;
  height: 510px;
  pointer-events: none;
  
  
  object-fit: contain;
  position: absolute;
  image-rendering: auto;
}

.flicker1 {
  animation: logo-flicker infinite 5s linear;

}
.flicker0 {
  animation: logo-flicker-i infinite 5s linear;
  

}
.pcircle {
  filter: invert(1) contrast(5) brightness(3);
  mix-blend-mode: difference;
  animation: App-logo-spin infinite 40s cubic-bezier(.34,-0.08,0,.1);
  opacity: 0;
  transition: .5s all;
  position: absolute;
}

.pcircle-word {
  filter: brightness(1);
  mix-blend-mode: difference;
  animation: App-logo-spin infinite 40s cubic-bezier(.34,-0.08,0,.1);
  opacity: 1;
  transition: .5s all;
  position: absolute;
}

@media (prefers-reduced-motion: no-preference) {
  .Appp-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@keyframes App-bg-blend {
  0% {
    opacity: 0;
  }
  64% {
    opacity: 0;
  }
  69% {
    opacity: 1;
  }
  96% {
    opacity: 1;
  }
}
@keyframes App-holder-blend {
  0% {
    mix-blend-mode: normal;
  }
  64% {
    mix-blend-mode: normal;
  }
  69% {
    mix-blend-mode: color-dodge;
  }
  96% {
    mix-blend-mode: color-dodge;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes logo-flc-1 {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  72% {
    opacity: .8;
  }
  74% {
    opacity: 1;
  }
  78% {
    opacity: .2;
  }
  83% {
    opacity: 1;
  }
}
@keyframes logo-flc-2 {
  0% {
    opacity: 1;
  }
  78% {
    opacity: 1;
  }
  80% {
    opacity: .8;
  }
  82% {
    opacity: 1;
  }
  86% {
    opacity: .2;
  }
  91% {
    opacity: 1;
  }
}
@keyframes logo-flc-3{
  0% {
    opacity: 1;
  }
  83% {
    opacity: 1;
  }
  85% {
    opacity: .8;
  }
  87% {
    opacity: 1;
  }
  91% {
    opacity: .2;
  }
  96% {
    opacity: 1;
  }
}

@keyframes logo-rot-l {
  0% {
    transform: translateX(-100px) rotate(0deg);
  }
  80% {
    transform: translateX(-100px)
  }
  83% {
    transform: translateX(-0) rotate(0deg);
  }
  
  
  95% {
    transform: translateX(00px)rotate(35deg);
    opacity: 0;
  }
}

@keyframes logo-flicker {
  0% {
    opacity: 0;
  }
  85% {
    opacity: 0;
  }
  86% {
    opacity: 1;
  }
  90% {
    opacity: 1;
    transform: scale(1.06);
  }
  98% {
    opacity: 1;
    transform: scale(1.06);
  }
}
@keyframes logo-flicker-i {
  0% {
    opacity: 1;
    transform: scale(1.06);
  }
  85% {
    opacity: 1;
  }
  84% {
    opacity: 1;
  }
  88% {
    opacity: 0;
    
  }
  98% {
    opacity: 0;
    
  }
}


@media only screen and (max-width: 600px) {
  
.l_circle {
  width: 264px;
  height: 264px;
  border: 38px solid blue
}
.l_line {
  height: 380px;
  width: 38px;
}
.l_left {
  transform: translateX(-84px);
  
}
.l_right {
  transform: translateX(84px);
  
}
.App-logo {
  
  width: 388px;
  height: 388px;
}
.l_butt {
    padding: 12px;
    padding-left: calc(50vw - 48px);
    padding-right: calc(50vw - 48px);
    bottom: 0px;
}
.l_butt {
  border: 1px solid black;
    opacity: 1;
    color: black;
}
.logo_holder:hover > .l_butt {
  opacity: 1;
  border: 1px solid white;
    
    color: white;
}


}
@media only screen and (max-width: 400px) {
  
  .l_circle {
    width: 230px;
    height: 230px;
    border: 33px solid blue
  }
  .l_line {
    height: 330px;
    width: 33px;
  }
  .l_center {
    
  }
  .l_left {
    transform: translateX(-72px);
    
  }
  .l_right {
    transform: translateX(72px);
    
  }
  .App-logo {
    
    width: 337px;
    height: 337px;
  }
  .l_butt {
      padding: 12px;
      padding-left: calc(50vw - 48px);
      padding-right: calc(50vw - 48px);
      bottom: 0px;
  }
  .l_butt {
    border: 1px solid black;
      opacity: 1;
      color: black;
  }
  .logo_holder:hover > .l_butt {
    opacity: 1;
    border: 1px solid white;
      
      color: white;
  }
  
  
  }